// @ts-strict-ignore
import _ from 'lodash';
import { ITEM_TYPES } from '@/homescreen/homescreen.constants';

export function isFolder(item) {
  return _.get(item, 'type') === ITEM_TYPES.FOLDER;
}

export function isProject(item) {
  return _.get(item, 'type') === ITEM_TYPES.PROJECT;
}
