import React from 'react';
import { createRoot } from 'react-dom/client';
import { init18n } from '@/utilities/i18n.utilities';
import 'core-js';
import 'regenerator-runtime/runtime';
import './styles';
import 'moment-duration-format';
import './wdyr'; // Note this must be the first import for it to work
import { Application } from '@/main/Application.page';

init18n();
const root = createRoot(document.getElementById('sqApp') || document.createElement('div'));
root.render(<Application />);
