import React from 'react';
import { Icon } from '@/core/Icon.atom';
import { isSeeqPreviewEnabled } from '@/services/systemConfiguration.utilities';
import { useNavigate } from 'react-router-dom';
import { getWorkbooksLink } from '@/main/routing.utilities';
import seeqLogo from '@/../public/img/Seeq_logo_white@2x.png';
import seeqLogoPreview from '@/../public/img/Seeq_preview_logo_white@2x.png';

interface HeaderLogoProps {}

export const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = () => {
  const navigate = useNavigate();

  const gotoHomeScreen = () => {
    navigate(getWorkbooksLink());
  };

  return (
    <div className="min-width-145 headerLogo" data-testid="headerLogo">
      <Icon
        type="white"
        onClick={gotoHomeScreen}
        icon="fa-th-large"
        extraClassNames="fa-xlg navbarHomeButton"
        testId="headerIcon"
        id="workbooksShowButton"
      />

      <div className="navbar-nav pl0 pr0 cursorPointer">
        <img
          className="mt4 mr15 mb5 ml5"
          src={isSeeqPreviewEnabled() ? seeqLogoPreview : seeqLogo}
          height={28}
          width={isSeeqPreviewEnabled() ? 130 : 80}
          onClick={gotoHomeScreen}
          data-testid="headerImage"
        />
      </div>
    </div>
  );
};
