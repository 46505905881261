import React, { useRef } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/formbuilder/formBuilder.constants';
import { sqWorkbenchStore } from '@/core/core.stores';
import {
  setCurrentUser,
  setDarkMode,
  setPreferNewTab,
  setUserProfileDisplay,
  setUserTimeZone,
} from '@/workbench/workbench.actions';
import { sqUsersApi, UserInputV1 } from '@/sdk';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { switchLanguage } from '@/utilities/i18n.utilities';
import { LOCALE_DATA, LocaleKey, LOCALES } from '@/utilities/i18n.constants';
import { FakeLink } from '@/core/FakeLink';
import { switchMode } from '@/utilities/utilities';

interface EditUserPreferencesProps {
  closeFn: () => void;
}

interface updateUserValues {
  firstName: string;
  lastName: string;
  email: string;
  timezone: any;
  language: { text: string; value: LocaleKey };
  preferNewTab: boolean;
  userDarkMode: boolean;
}

export const EditUserPreferencesModal: React.FunctionComponent<EditUserPreferencesProps> = ({ closeFn }) => {
  const { t } = useTranslation();

  const { currentUser: user, userTimeZone, userLanguage, preferNewTab, darkMode } = sqWorkbenchStore;

  const doUseDarkMode = useRef(darkMode);
  const isCancelEnabled = userTimeZone;

  const formattedLanguageOptions = _.map(LOCALE_DATA, (language) => ({
    text: language.text,
    value: language.key,
  }));

  const selectedLanguage = _.find(formattedLanguageOptions, {
    value: userLanguage,
  });

  const openUserProfile = () => {
    setUserProfileDisplay(true);
    closeFn();
  };

  const updateUser = (values: updateUserValues) => {
    const { firstName, lastName, email, timezone, language, preferNewTab } = values;
    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;
    const userProps = { email, firstName, lastName, name };

    if (darkMode !== values.userDarkMode) {
      setDarkMode(values.userDarkMode);
    }

    return sqUsersApi
      .updateUser(userProps as UserInputV1, { id: user.id })
      .then(() => setCurrentUser())
      .then(() => setUserTimeZone(timezone))
      .then(() => setPreferNewTab(preferNewTab))
      .then(() => switchLanguage(language?.value ?? LOCALES.EN))
      .then(() => successToast({ messageKey: 'USER.PREFERENCES_UPDATED' }))
      .then(() => (user.firstName ? closeFn() : setUserProfileDisplay(true)))
      .catch((error) => errorToast({ httpResponseOrError: error }));
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userPreferences',
      components: [
        {
          component: 'TimeZoneSelectorFormComponent',
          name: 'timezone',
          label: 'USER.TIME_ZONE',
          value: userTimeZone || sqTimezones.defaultTimezone,
          onChange: _.noop,
          defaultTimeZone: sqTimezones.defaultTimezone,
          skipStore: true,
          testId: 'timezone',
        },
        {
          component: 'IconSelectFormComponent',
          name: 'language',
          label: 'USER.LANGUAGE.SELECTION',
          value: selectedLanguage,
          onChange: _.noop,
          selectOptions: formattedLanguageOptions,
          insideModal: true,
          skipStore: true,
          testId: 'language',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'userPreferNewTab',
          name: 'preferNewTab',
          label: '',
          onChange: _.noop,
          skipStore: true,
          checkboxLabel: 'USER.PREFER_NEW_TAB',
          value: preferNewTab,
        },
        {
          component: 'CheckboxFormComponent',
          id: 'userDarkMode',
          name: 'userDarkMode',
          label: '',
          onChange: () => {
            switchMode(!doUseDarkMode.current);
            doUseDarkMode.current = !doUseDarkMode.current;
          },
          skipStore: true,
          checkboxLabel: 'DARK_MODE.ENABLE',
          value: doUseDarkMode.current,
        },
      ],
    },
  ];

  const closeAndReset = () => {
    // if a user clicks the cancel button we need to make sure that we
    // toggle the darkmode display back to the intended display
    if (doUseDarkMode.current !== darkMode) {
      switchMode(darkMode);
    }
    closeFn();
  };

  return (
    // The close and cancel buttons get hidden when the user has not set their timezone to require the user
    // to save their preferences, which will set their timezone
    <Modal show={true} onHide={closeAndReset} animation={false} data-testid="editUserPreferences">
      <Modal.Header closeButton={isCancelEnabled}>
        <div className="flexRowContainer">
          <h3 className="mr-auto">{t('EDIT_PREFERENCES')}</h3>
          <FakeLink testId="openUserProfile" onClick={openUserProfile} extraClassNames="text-underline">
            {t('EDIT_PROFILE')}
          </FakeLink>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserPreferences">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            submitFn={updateUser as any}
            submitBtnLabel={user.firstName ? 'SAVE' : 'NEXT'}
            closeFn={closeAndReset}
            hideCancel={!isCancelEnabled}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
